@import '../constants.scss';

.SolutionModal {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50rem;
    background-color: $intellyq-white;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;
    border-radius: 5px;
    padding-bottom: 2rem;

    .close-icon {
        fill: $intellyq-black;
        width: 24px;
        float: right;
        margin-right: 1.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        transition: $intellyq-transition;

        &:hover {
            fill: $intellyq-main;
        }
    }

    .content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        color: $intellyq-font-color;
        padding: 1.5em 0;

        .solution-title {
            font-size: $intellyq-large-font-size;
            margin-bottom: 1em;
        }

        .solution-text {
            text-align: left;
            white-space: pre-line;
        }
    }

    @include apply-to-mobile() {
        width: 100%;
        height: 100%;
    }
}