@import '../constants.scss';

div.ui.container.Footer {
    width: 100% !important;
    height: 18rem;
    border-top: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    position: relative;

    @include apply-to-mobile() {
        margin-left: 0 !important;
    }

    .copyright-name {
        position: absolute;
        left: 8rem;
        bottom: 3.5rem;
        font-family: Arial, Helvetica, sans-serif;
        color: $intellyq-font-color;

        @include apply-to-mobile() {
            left: 2rem;
        }
    }

    .sitemap-link {
        position: absolute;
        left: 8rem;
        bottom: 2rem;
        font-family: Arial, Helvetica, sans-serif;
        color: $intellyq-font-color;
        font-weight: 600;

        @include apply-to-mobile() {
            left: 2rem;
        }
    }

    div.ui.container.AboutUsColumn,
    div.ui.container.ContactUsColumn,
    div.ui.container.CourseLinksColumn,
    div.ui.container.TestPackLinksColumn {
        width: 10rem;
        margin-left: 8rem !important;
        margin-right: 0 !important;
        margin-top: 2rem;

        @include apply-to-mobile() {
            margin-left: 2rem !important;
        }

        .column-header {
            font-family: Arial, Helvetica, sans-serif;
            margin-bottom: 1rem;
        }

        .column-content {
            display: flex;
            flex-direction: column;

            a, div {
                font-family: Arial, Helvetica, sans-serif;
                color: $intellyq-font-color-light-grey;
                font-weight: 500;
                margin-bottom: 5px;
                transition: $intellyq-transition;
                font-size: 0.95rem;
                cursor: pointer;

                &:hover {
                    color: $intellyq-font-color;
                }
            }
        }
    }

    div.ui.container.CourseLinksColumn {
        @include apply-to-mobile() {
            display: none;
        }
    }
}
