@import '../../constants.scss';

div.ui.container.Billing {
    padding: 2rem 4rem;

    @include apply-to-mobile() {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0;
    }

    h3.billing-header {
        font-family: Arial, Helvetica, sans-serif;
        font-size: $intellyq-medium-font-size;
        margin-bottom: 2rem;
    }

    .billing-status {
        font-family: Arial, Helvetica, sans-serif;
        font-size: $intellyq-small-font-size;
        font-weight: 600;

        .billing-plan {
            font-family: Arial, Helvetica, sans-serif;
            color: $intellyq-main;
        }
    }

    .renewal-message {
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 5px;
    }

    .cancellation-message {
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 5px;
        width: 50%;
        font-size: 0.9rem;
    }

    button.upgrade-button,
    button.cancel-button {
        padding: 10px 20px;
        background-color: $intellyq-main;
        color: white;
        border: none;
        border-radius: 4px;
        transition: $intellyq-transition;
        margin-top: 3rem;
        cursor: pointer;

        &:hover {
            background-color: #1571c7;
        }
    }

}

div.ui.page.modals.dimmer.transition.visible.active {
    div.ui.small.modal.transition.visible.active {
        div.content {
            font-family: Arial, Helvetica, sans-serif;
        }

        div.actions {
            button.ui.button {
                font-family: Arial, Helvetica, sans-serif;
            }

            button.ui.primary.button {
                font-family: Arial, Helvetica, sans-serif;
                background-color: $intellyq-main;

                &:hover {
                    background-color: #1571c7;
                }
            }
        }
    }
}