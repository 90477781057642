@import '../constants.scss';

.Contribute {
    width: 100;

    div.ui.container.content {
        position: relative;
        height: 60vh;
        width: 100%;

        @media screen and (max-width: 890px) {
            height: 80vh;
        }

        @media screen and (max-width: 600px) {
            height: 105vh;
            text-align: center;
        }

        @media screen and (min-width: 1500px) {
            width: 85%;
        }

        @media screen and (min-width: 1700px) {
            width: 80%;
        }

        .contribution-header-and-link {
            position: absolute;
            left: 1em;
            top: 10rem;
            

            @media screen and (max-width: 965px) {
                left: 0.5rem;
            }

            @media screen and (max-width: 890px) {
                text-align: center;
                position: inherit;
                top: 4rem;
                left: 0;
            }

            .contribution-header {
                font-family: Arial, Helvetica, sans-serif;
                margin-bottom: 2rem;

                @media screen and (max-width: 1200px) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 1000px) {
                    font-size: 1.5rem;
                }
            }

            .contribution-form-link {
                margin-left: 35%;
                font-family: Arial, Helvetica, sans-serif;
                cursor: pointer;
                border: 1px solid $intellyq-font-color;
                color: $intellyq-font-color;
                transition: $intellyq-transition;
                padding: 10px 25px;

                @media screen and (max-width: 890px) {
                    margin-left: 0;
                }

                &:hover {
                    background-color: $intellyq-font-color;
                    color: white;
                }
            }
        }

        .rewards-row {
            display: flex;
            justify-content: center;
            width: 45%;
            position: absolute;
            right: 4rem;
            top: 5rem;

            @media screen and (max-width: 1045px) {
                right: 2rem;
            }

            @media screen and (max-width: 890px) {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 10rem;
                right: 0;
                left: 1rem;
            }

            @media screen and (max-width: 600px) {
                display: block;
            }

            .upload-document-reward,
            .upgrade-friend-reward {
                padding: 4rem;
                box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
                margin-right: 2rem;
                display: flex;
                flex-direction: column;
                text-align: center;

                @media screen and (max-width: 890px) {
                    margin-bottom: 1rem;
                }

                .reward-title {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1.8rem;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    line-height: 2rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 1.5rem;
                    }
                }

                .reward-value {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1rem;
                }

                .reward-link {
                    font-family: Arial, Helvetica, sans-serif;
                    cursor: pointer;
                    border: 1px solid $intellyq-font-color;
                    color: $intellyq-font-color;
                    transition: $intellyq-transition;
                    margin-top: 2rem;
                    padding: 8px 8px;

                    &:hover {
                        background-color: $intellyq-font-color;
                        color: white;
                    }
                }

                .referral-button {
                    font-family: Arial, Helvetica, sans-serif;
                    cursor: pointer;
                    border: 1px solid $intellyq-font-color;
                    color: $intellyq-font-color;
                    transition: $intellyq-transition;
                    margin-top: 2rem;
                    padding: 8px 8px;

                    &:hover {
                        background-color: $intellyq-font-color;
                        color: white;
                    }
                }

                .referral-code {
                    margin-top: 2rem;
                    padding: 8px 5px;
                    font-family: Arial, Helvetica, sans-serif;
                    border: 1px solid $intellyq-font-color;
                    font-weight: 600;
                }

                .referral-message {
                    width: 15rem;
                    margin-top: 0.5rem;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .contribution-value-props {
        background-color: #f7f7f9;
        width: 100% !important;
        padding: 2rem 5rem;
        height: calc(40vh - 4.5rem);

        @include apply-to-mobile() {
            width: 100% !important;
            margin-left: 0em !important;
            height: 65vh;
        }

        .contribution-value-header {
            font-family: Arial, Helvetica, sans-serif;
            margin-bottom: 2rem;

            @media screen and (max-width: 890px) {
                font-size: 1.5rem;
            }
        }

        div.ui.grid {
            div.three.column.row {
                @include apply-to-mobile() {
                    flex-direction: column;
                }

                div.column.value-prop {
                    @include apply-to-mobile() {
                        width: 100% !important;
                        margin-bottom: 2rem !important;
                    }

                    .value-prop-header {
                        font-family: Arial, Helvetica, sans-serif;
                        margin-bottom: 5px;
                    }

                    .value-prop-description {
                        font-family: Arial, Helvetica, sans-serif;
                        width: 80%;
                    }
                }
            }
        }
    }
}
