@import '../../constants.scss';

div.ui.container.TestPage {
    position: relative;
    width: 100%;

    @media screen and (max-width: 850px) {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .test-header {
        width: 100%;
        height: 6rem;
        background-color: $intellyq-grey-lighter;
        display: flex;

        @media screen and (max-width: 600px) {
            height: 4rem;
        }

        .test-info-container {
            width: 65%;
            padding-left: 2rem;
            padding-top: 1.5rem;

            @media screen and (max-width: 1200px) {
                width: 50%;
            }

            @media screen and (max-width: 600px) {
                width: 60%;
                padding-left: 1rem;
            }

            .test-course {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
                color: $intellyq-main;
                font-size: 1.1rem;
            }

            .number-of-tries {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 0.9rem;
                margin-top: 5px;

                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }

        .test-results {
            width: 20%;
            padding-top: 2.5rem;

            @media screen and (max-width: 1200px) {
                width: 35%;
            }

            @media screen and (max-width: 600px) {
                width: 40%;
                padding-top: 1.65rem;
            }

            .score {
                margin-left: 4rem;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
                font-size: 1.1rem;

                @media screen and (max-width: 600px) {
                    margin-left: 0rem;
                }

                &.excellent {
                    color: #2ab573;
                }

                &.average {
                    color: orange;
                }

                &.bad {
                    color: #ff5c6e;
                }
            }
        }

        .submit-test-button {
            margin-top: 2rem;
            font-family: Arial, Helvetica, sans-serif;
            background-color: $intellyq-main;
            color: white;
            max-height: 2.5rem;
            padding-left: 2rem;
            padding-right: 2rem;
            transition: $intellyq-transition;
            border: none;
            border-radius: 20px;
            font-size: 1.05rem;
            cursor: pointer;

            @media screen and (max-width: 800px) {
                display: none;
            }

            &:hover {
                background-color: #1571c7;
            }

            &.inactive {
                background-color: rgba(0, 119, 154, 0.3);
            }
        }
    }

    .submit-test-button-mobile {
        position: absolute;
        bottom: 1.5rem;
        font-family: Arial, Helvetica, sans-serif;
        background-color: $intellyq-main;
        color: white;
        padding: 0.9rem 2.2rem;
        transition: $intellyq-transition;
        border: none;
        border-radius: 20px;
        font-size: 1.15rem;
        cursor: pointer;
        width: 9rem;
        margin: 0 auto !important;
        left: 0;
        right: 0;

        &.inactive {
            background-color: #00779abf;
        }

        @media screen and (min-width: 801px) {
            display: none;
        }
    }

    .test-content {
        padding: 2rem 10rem;
        height: calc(100vh - 10.5rem);
        overflow: scroll;

        @media screen and (max-width: 850px) {
            padding: 3.5rem 2rem;
        }

        @media screen and (max-width: 600px) {
            padding: 3.5rem 0rem;
            height: calc(100vh - 8.5rem);
        }

        .noQuestions {
            text-align: center;
            margin-top: 6rem;
            width: 90%;
            margin-left: auto;
            margin-right: auto;

            .illustration {
                img {
                    height: 15rem;

                    @media screen and (max-width: 580px) {
                        height: 15rem;
                    }

                    @media screen and (max-width: 430px) {
                        height: 12rem;
                    }
                }
            }

            .no-questions-message {
                margin-top: 3rem;
                font-family: Arial, Helvetica, sans-serif;
            }
        }
    }
}
