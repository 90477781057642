@import './constants.scss';

[class^='icon-'],
[class*=' icon-'] {
    // use !important to prevent issues with browser extensions that change fonts
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mark:before {
    content: '\e900';
}

* {
    font-family: Arial, Helvetica, sans-serif;
    -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

p {
    margin: 0;
}

.mono {
    font-family: GT-Pressura-Mono;
}

body {
    margin: 0;
    padding: 0;
    font-family: Calibre;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: 'pnum';
    font-feature-settings: 'pnum';
    font-variant-numeric: proportional-nums;
    color: $intellyq-font-color;
}

ul {
    padding-left: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

#root {
    min-height: 100%;
    width: 100%;
    overflow-x: hidden;
}

// Semantic UI override
.ui.card {
    .content {
        .header {
            font-family: Publico;
        }
    }
}

::-webkit-scrollbar {
    display: none;
}

.mapboxgl-ctrl {
    display: none;
}

a.mapboxgl-ctrl-logo {
    display: none;
}

.ui.menu {
    font-size: $intellyq-small-font-size;
    margin-top: -8px;
}

.ui.menu:not(.vertical) .item {
    color: $intellyq-font-color-light-grey;
    transition: $intellyq-transition;
}

.ui.menu a.item:hover {
    color: $intellyq-font-color-dark-grey;
    background-color: $intellyq-grey-lightest;
}

.ui.menu .active.item {
    background-color: $intellyq-grey-lighter;
    color: $intellyq-font-color-dark-grey;
    font-weight: 400;

    &:hover {
        background-color: $intellyq-grey-lightest;
        color: $intellyq-font-color-dark-grey;
    }
}

// semantic-ui overrides
.ui.checkbox {
    input:checked ~ label:after {
        transition: background-color 1s ease;
        background-color: $intellyq-main;
        // there's some really deeply nested css selector setting
        // the color to black when checkbox is focused and checked
        color: white !important;
        border-radius: 4px;
    }

    label {
        color: $intellyq-font-color-light-grey;
        font-size: $intellyq-small-font-size;
    }

    input:focus ~ label {
        color: $intellyq-font-color-light-grey;
    }
}

.ui.button {
    font-family: Calibre;
    font-weight: 400;
    font-size: $intellyq-small-font-size;
}

.ui.input {
    font-size: $intellyq-small-font-size;
    color: $intellyq-font-color;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

button:focus {
    outline: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
