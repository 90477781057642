@import '../constants.scss';

div.ui.container.QuestionDetailsPage {
    width: 100%;
    height: calc(100vh - 4.5rem);
    margin-left: 0 !important;
    margin-right: 0 !important;

    .header-container {
        width: 100%;
        display: flex;

        .question-container {
            position: relative;
            width: 66%;
            padding: 3.5rem;
            padding-top: 4rem;

            @include apply-to-mobile() {
                width: 100%;
                padding: 4rem 1.5rem;
            }

            @media screen and (min-width: 1400px) {
                padding: 5rem;
                padding-top: 4rem;
            }

            .question {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
                font-size: 1.5rem;
            }

            .answers {
                margin-top: 2rem;
                margin-left: 2rem;

                .answer {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1.1rem;
                    margin-bottom: 10px;
                }
            }

            .report-button {
                display: flex;
                border: none;
                background: none;
                position: absolute;
                bottom: 0.75rem;
                right: 1rem;
                font-size: 0.85rem;
                color: $intellyq-font-color-light-grey;
                cursor: pointer;
                z-index: 5;

                .flag-illustration {
                    display: inline-block;
                    margin-right: 5px;
                    height: 0.85rem;
                }
            }
        }

        .key-details-container-desktop {
            width: 34%;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 2rem 0;
            padding-left: 1rem;
            padding-bottom: 3.5rem;
            background-color: $intellyq-main;
            background-image: $intellyq-background-pattern;

            @include apply-to-mobile() {
                display: none;
            }

            div.ui.container.KeyDetails {
                width: 100%;
                padding-left: 3rem;

                h3.table-header {
                    margin-bottom: 3rem;
                    font-weight: 500;
                }

                .table-content {
                    div.ui.centered.stackable.two.column.grid {
                        div.sixteen.wide.column {
                            padding-bottom: 0.7rem;
                            padding-top: 0.7rem;

                            .key-details-title {
                                vertical-align: top;
                                font-size: 1rem;
                            }

                            .key-details-value {
                                margin-left: 0;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-sections {
        background-color: $intellyq-grey-lightest;
        padding-top: 0.5rem;
        padding-bottom: 0rem;

        @include apply-to-mobile() {
            padding-top: 0;
        }

        .key-details-container {
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 0;
            padding: 3rem 0;
            padding-bottom: 3.5rem;
            background-color: $intellyq-main;
            background-image: $intellyq-background-pattern;

            @media screen and (min-width: 801px) {
                display: none;
            }
        }

        div.ui.container.SimilarQuestions,
        div.ui.container.QuestionAnalytics {
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 0.75rem;
            padding: 2rem 6rem;
            padding-bottom: 2.5rem;
            background-color: white;
            border-radius: 5px;

            @include apply-to-mobile() {
                padding: 2rem 1rem;
            }
        }

        div.ui.container.SimilarQuestions {
            padding-bottom: 5rem;
        }
    }

    .Toastify {
        .Toastify__toast-container.Toastify__toast-container--top-center {
            .Toastify__toast.Toastify__toast--success {
                background-color: white;

                .Toastify__toast-body {
                    font-family: Arial, Helvetica, sans-serif;
                    color: $intellyq-font-color;
                    margin-left: 1rem;
                }

                button {
                    color: $intellyq-font-color;
                }
            }
        }
    }

    .loader-container {
        height: 80vh;
        padding-top: 30vh;
    }

    .question-not-found {
        margin-top: 10rem;
        margin-bottom: 10rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        text-align: center;

        @include apply-to-mobile() {
            margin-top: 5rem;
            margin-bottom: 5rem;
            padding: 0 1em;
            font-size: 1rem;
        }

        .not-found-icon {
            margin-bottom: 2rem;

            svg {
                height: 16rem;

                @include apply-to-mobile() {
                    height: 12rem;
                    width: 12rem;
                }
            }
        }

        .browse-link {
            margin-top: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            display: block;
        }
    }
}
