@import '../../constants.scss';

div.ui.container.Settings {
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;

    @include apply-to-mobile() {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0;
    }

    h3.settings-header {
        font-family: Arial, Helvetica, sans-serif;
        font-size: $intellyq-medium-font-size;
        margin-bottom: 2rem;
    }

    .input-row {
        display: flex;
        margin-bottom: 2.5rem;
        width: 100%;

        @include apply-to-mobile() {
            display: initial;
        }

        .input-section {
            width: 100%;
            margin-right: 5rem;

            h5 {
                font-family: Arial, Helvetica, sans-serif;
                font-size: $intellyq-tiny-font-size;
                margin-bottom: 0.8rem;
                color: $intellyq-font-color-light-grey;
            }

            div.input {
                margin-bottom: 2rem;
                width: 100%;
                font-size: $intellyq-tiny-font-size;

                @media screen and (max-width: 500px) {
                    width: 100%;
                }
            }

            .email-display {
                font-family: Arial, Helvetica, sans-serif;
                font-size: $intellyq-tiny-font-size;
                font-weight: 600;

                @include apply-to-mobile() {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .password-validation-message {
        font-family: Arial, Helvetica, sans-serif;
        color: red;
        margin-top: -3.5rem;
        margin-bottom: 2.1rem;
    }

    button.save-button {
        padding: 10px 20px;
        background-color: $intellyq-main;
        color: white;
        border: none;
        border-radius: 4px;
        transition: $intellyq-transition;
        cursor: pointer;

        &:hover {
            background-color: #1571c7;
        }
    }

    div.ui.divider {
        width: 92%;
        margin-bottom: 2rem;
    }
}