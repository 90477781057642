@import '../constants.scss';

.TestPackBar {
    width: 70%;
    max-height: calc(100vh - 7rem);
    position: absolute;
    transition: opacity 0.2s ease-in;
    top: 7rem;
    left: 27%;
    overflow: scroll;
    padding: 0 5rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1500px) {
        width: 82%;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        left: 0;
        padding-right: 4.5rem;
        padding-left: 4.5rem;
        max-width: 100%;
        overflow-x: hidden;
    }

    .test-packs-bar-header {
        margin-bottom: 2em;

        .test-packs-bar-title {
            font-size: $intellyq-large-font-size;
        }

        .test-packs-bar-description {
            margin-top: 5px;
            margin-bottom: 15px;
        }

        hr {
            border: $intellyq-border-light;
        }
    }

    .test-packs-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.5em;
    }

    .test-pack-card {
        padding: 3rem 3rem;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        width: 19em;
        text-align: center;

        @media screen and (max-width: 500px) {
            padding: 2.5rem 3em;
            min-width: 17.5rem;
        }

        .course-code {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        .course-name {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1rem;
        }

        .university-name {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 0.9rem;
        }

        .course-questions-link {
            font-family: Arial, Helvetica, sans-serif;
            cursor: pointer;
            border: 1px solid $intellyq-font-color;
            color: $intellyq-font-color;
            transition: $intellyq-transition;
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding: 8px 8px;

            &:hover {
                background-color: $intellyq-font-color;
                color: white;
            }
        }
    }
}
