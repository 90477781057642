@import '../constants.scss';

.Page404 {
    width: 100%;
    text-align: center;
    padding-top: 10rem;

    .illustration {
        height: 20rem;

        @media screen and (max-width: 580px) {
            height: 15rem;
        }

        @media screen and (max-width: 430px) {
            height: 12rem;
        }
    }

    .message {
        margin-top: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    a.link-to-home {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
    }
}
