@import '../../constants.scss';

div.ui.container.TestQuestion {
    margin-bottom: 5rem;
    padding-bottom: 1rem;
    position: relative;

    .question-text-container {
        display: flex;
        padding-left: 1.5rem;

        .question {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
        }

        .result-illustration-container {
            position: absolute;
            width: 1.5rem;
            left: -2rem;
            top: 5px;

            @media screen and (max-width: 800px) {
                left: -0.5rem;
            }

            .correct-result-illustration {
                svg#Capa_1 {
                    height: 1.4rem;
                    width: 1.4rem;
                    fill: #2ab573;
                }
            }

            .incorrect-result-illustration {
                svg#Capa_1 {
                    height: 1.2rem;
                    width: 1.2rem;
                    fill: #ff5c6e;
                }
            }
        }
    }

    .answers {
        margin-top: 10px;
        margin-left: 2.5rem;

        .answer {
            margin-bottom: 5px;

            .answer-text {
                font-family: Arial, Helvetica, sans-serif;
            }

            div.ui.checked.fitted.radio.checkbox {
                label::after {
                    border-radius: 20px !important;
                    background-color: $intellyq-main !important;
                }
            }
        }
    }

    .ViewAnswerButton {
        bottom: -2.5rem;
        left: 1.5rem;
    }
}
