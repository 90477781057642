@import '../constants.scss';

div.ui.container.Upgrade {
    width: 100% !important;
    margin-left: 0 !important;

    div.ui.container.content {
        text-align: center;

        @media screen and (max-width: 992px) {
            width: 95%;
        }
    }

    .upgrade-header {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2.2rem;
        margin-top: 3rem;
    }

    .upgrade-subheader {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin-top: 1rem;
        margin-bottom: 4rem;
    }

    div.ui.container.pricing {
        display: flex;
        margin-bottom: 8rem;

        @include apply-to-mobile() {
            flex-direction: column;
        }

        @media screen and (max-width: 992px) {
            width: 100%;
        }

        div.ui.container.premium-quarterly,
        div.ui.container.premium-monthly,
        div.ui.container.premium-yearly {
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            width: 31%;
            height: 34rem;
            padding: 10px;
            position: relative;
            border-radius: 2px;

            @include apply-to-mobile() {
                height: 35rem;
                margin-bottom: 2rem;
                width: 50% !important;
                margin-left: auto !important;
                margin-right: auto !important;
            }

            @media screen and (max-width: 600px) {
                width: 100% !important;
            }

            .pricing-header {
                box-shadow: 0 1px 7px 5px rgba(0, 0, 0, 0.05);
                text-transform: uppercase;
                margin-top: 2rem;
                width: 13rem;
                align-self: center;
                padding: 8px 20px;
                font-family: Arial, Helvetica, sans-serif;
                font-size: $intellyq-medium-font-size;
            }

            .price-per-month {
                font-family: Arial, Helvetica, sans-serif;
                font-size: $intellyq-medium-font-size;
                color: $intellyq-font-color-light-grey;
                margin-top: 2rem;
                margin-bottom: 2rem;

                .price {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: $intellyq-large-font-size;
                    color: $intellyq-font-color;
                    font-weight: 600;
                }
            }

            .upgrade-button {
                position: absolute;
                bottom: 3rem;
                align-self: center;
                font-family: Arial, Helvetica, sans-serif;
                font-size: $intellyq-tiny-font-size;
                cursor: pointer;
                background-color: $intellyq-main;
                border-radius: 4px;
                color: white;
                transition: $intellyq-transition;
                padding: 12px 3rem;

                &:hover {
                    background-color: #1571c7;
                    color: white;
                }
            }

            .ui.container.price-details {
                display: inline-flex;
                width: 80%;
                margin-bottom: 0;
                margin-top: 1rem;

                @include apply-to-mobile() {
                    margin-left: 5% !important;
                }

                .checkmark-icon {
                    display: inline-block;
                    margin-right: 1rem;

                    svg#Capa_1 {
                        width: 20px;
                        height: 20px;
                        fill: $intellyq-main;
                    }
                }

                h3.offered {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    display: inline-block;
                    text-align: left;
                    font-size: 1.05rem;
                }
            }

            .billing-info {
                font-family: Arial, Helvetica, sans-serif;
                position: absolute;
                bottom: 7rem;
                align-self: center;
            }

            .billing-promo {
                font-family: Arial, Helvetica, sans-serif;
                position: absolute;
                bottom: 8.5rem;
                align-self: center;
                color: $intellyq-main;
                font-weight: 600;
            }
        }

        div.ui.container.premium-yearly {
            border: 2px solid $intellyq-main;
            box-shadow: none;

            .pricing-header {
                margin-top: 1rem;
            }

            .most-popular {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.9rem;
                color: $intellyq-main;
            }
        }
    }
}
