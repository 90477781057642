@import '../../constants.scss';

div.ui.container.KeyDetails {
    width: 75%;

    h3.table-header {
        font-weight: 600;
        font-size: $intellyq-semi-large-font-size;
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 3rem;
    }

    .key-details-title {
        font-family: Arial, Helvetica, sans-serif;
        color: $intellyq-grey-light;
        font-weight: 600;
        font-size: $intellyq-small-font-size;
        display: inline-block;
        width: 30%;
        vertical-align: top;
    }

    .key-details-value {
        margin-left: 1rem;
        font-size: $intellyq-small-font-size;
        font-weight: 500;
        color: white;
        display: inline-block;
        width: 65%;
        font-family: Arial, Helvetica, sans-serif;
    }
}