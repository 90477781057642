@import '../constants.scss';

.SearchBar {
    &.ui.search {
        width: calc(50% - 9rem);
        border-radius: 0;

        .ui.input {
            width: 100%;

            input {
                border-radius: 5px;
            }
        }

        > .results {
            width: 100%;
            box-shadow: $intellyq-box-shadow;
            border: 1px solid #eeefef;
            border-radius: 3px;

            &.visible .category .results {
                display: table;
                width: 100%;
            }

            &.visible {
                max-height: 90vh;
            }

            .category {
                @include apply-to-mobile() {
                    max-height: 300px;
                }

                max-height: 90vh;
                overflow-y: scroll;

                .result {
                    background: white;
                    padding-top: 0.5rem !important;
                    padding-bottom: 0.5rem !important;

                    .title {
                        font-family: Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        font-size: $intellyq-tiny-font-size;
                        color: $intellyq-font-color;
                        display: inline-block;
                    }

                    .description {
                        font-family: Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-size: $intellyq-tiny-font-size;
                        color: $intellyq-font-color-light-grey;
                        white-space: pre-line;

                        @include apply-to-mobile() {
                            float: inherit;
                        }
                    }
                }
            }

            &.result.active {
                background: white !important;
            }
        }

        > .results .result:last-child {
            border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
        }
    }

    &.ui.category.search {
        > .results {
            width: 100%;

            .category,
            .category.active {
                display: block;
                background: none;

                .result {
                    background: white;
                    border: none;

                    &:hover {
                        background: $intellyq-grey-lighter;
                    }
                }

                > .name {
                    display: inline-flex;
                    background: $intellyq-grey-lightest;
                    color: $intellyq-font-color;
                    width: 100%;
                    font-weight: 400;

                    .category {
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: $intellyq-tiny-font-size;
                        margin-right: 0.5rem;

                        .icon {
                            width: 1rem;
                            fill: $intellyq-main;
                            margin-right: 5px;
                            margin-top: 3px;
                        }

                        .category-label {
                            font-family: Arial, Helvetica, sans-serif;
                            font-size: 1rem;
                            color: $intellyq-font-color;
                        }
                    }
                }
            }
        }
    }

    div.ui.right.icon.input {
        padding: 0.2rem;

        input.prompt {
            border-radius: 0;
            width: 100%;
            height: 100%;
        }
    }

    @include apply-to-mobile() {
        width: 100%;
        height: 3.5rem;
        margin-left: 0;

        input {
            width: 100%;
            height: 100%;
        }

        &.ui.search {
            width: 100%;

            > .results {
                margin-top: 0;
                width: 100%;
                max-width: 100%;
            }

            > .results .result:last-child {
                border-bottom: none;
            }
        }
    }
}

.ui.right.icon.input {
    width: 100%;
}
