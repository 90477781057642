@import '../../constants.scss';

div.ui.container.FeaturedCourses {
    margin-top: 1rem;
    text-align: center;
    width: 100%;
    background-color: #fbfafa;
    padding: 3em 0;

    .featured-courses-title {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 2.25rem;

        @media screen and (max-width: 700px) {
            font-size: 1.75rem;
        }
    }

    .featured-courses-description {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin-top: 0.5rem;
        font-size: 1.25rem;

        @media screen and (max-width: 700px) {
            font-size: 1rem;
        }
    }

    .courses-row {
        display: flex;
        position: relative;
        justify-content: normal;
        overflow-x: scroll;
        margin-top: 3rem;

        @media screen and (max-width: 700px) {
            padding: 1rem;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }

        .item-slider {
            width: 84%;
            padding: 1rem;
            margin-left: auto;
            margin-right: auto;

            @media screen and (max-width: 500px) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }

        .scroll-button {
            top: 8.5rem;
        }

        .scroll-button.right {
            right: 6.25%;
        }

        .scroll-button.left {
            left: 6%;
        }

        .course-card {
            padding: 3rem 4rem;
            box-shadow: 0 2px 18px 0 rgba(0,0,0,0.10);
            margin-right: 2rem;
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
            min-width: 19.5rem;

            @media screen and (max-width: 500px) {
                padding: 2.5rem 3em;
                min-width: 17.5rem;
            }

            .course-code {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1.8rem;
                font-weight: 500;
                margin-bottom: 1rem;
            }

            .course-name {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1rem;
            }

            .university-name {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 0.9rem;
            }

            .course-questions-link {
                font-family: Arial, Helvetica, sans-serif;
                cursor: pointer;
                border: 1px solid $intellyq-font-color;
                color: $intellyq-font-color;
                transition: $intellyq-transition;
                margin-top: 2rem;
                margin-bottom: 2rem;
                padding: 8px 8px;

                &:hover {
                    background-color: $intellyq-font-color;
                    color: white;
                }
            }
        }
    }
}