$intellyq-white: white;
$intellyq-grey-lightest: #f7f7f9;
$intellyq-grey-lighter: #f4f5f9;
$intellyq-grey-light: #efeff3;
$intellyq-grey-dark: #a9afb6;
$intellyq-grey-darker: #646971;
$intellyq-grey-darkest: #3e4247;

$intellyq-main: #0458a7;
$intellyq-main-dark: rgb(4, 61, 167);
$intellyq-black: #000;

$intellyq-red: #d44242;

$intellyq-font-color: #303339;
$intellyq-font-color-dark-grey: #3e4247;
$intellyq-font-color-light-grey: #646971;
$intellyq-font-color-lighter-grey: #a9afb6;

$intellyq-tiny-font-size: 1rem;
$intellyq-very-small-font-size: 1.1rem;
$intellyq-small-font-size: 1.15rem;
$intellyq-medium-font-size: 1.25rem;
$intellyq-semi-large-font-size: 1.3rem;
$intellyq-large-font-size: 1.5rem;
$intellyq-big-font-size: 1.75rem;
$intellyq-huge-font-size: 2rem;
$intellyq-semi-massive-font-size: 2.25rem;
$intellyq-pretty-massive-font-size: 2.5rem;
$intellyq-almost-massive-font-size: 2.85rem;
$intellyq-massive-font-size: 3rem;
$intellyq-very-massive-font-size: 3.25rem;

$intellyq-box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
$intellyq-strong-box-shadow: 0 2px 4px rgba(50, 50, 93, 0.15);

$intellyq-border-dark: 1px solid $intellyq-grey-dark;
$intellyq-border-light: 1px solid $intellyq-grey-light;
$intellyq-border-lighter: 1px solid $intellyq-grey-lighter;

$intellyq-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1) 0ms;

$intellyq-background-pattern: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23efeff3' fill-opacity='0.09'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

@mixin apply-to-mobile() {
    @media only screen and (max-width: 800px) {
        @content;
    }
}
