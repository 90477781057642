@import '../constants.scss';

.ViewAnswerButton {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: end;

    @media screen and (max-width: 600px) {
        width: 93%;
    }

    .view-answer-button {
        background-color: #1571c7;
        border: none;
        cursor: pointer;
        padding: 8px 16px;
        border-radius: 5px;
        color: white;
        transition: $intellyq-transition;
        margin-right: 1em;

        &:hover {
            background-color: #0458a7;
        }
    }

    .view-explanation-button {
        font-weight: 600;
        margin-right: 1rem;
        cursor: pointer;
        display: inline-block;
        color: $intellyq-main;
        transition: $intellyq-transition;
        margin-top: 10px;
        border: 1px solid $intellyq-main;
        padding: 7px 15px;
        border-radius: 5px;

        &:hover {
            color: #1571c7;
            border: 1px solid #1571c7;
        }
    }

    .correct-answer {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: $intellyq-main;
        font-size: 1.4rem;
        display: inline-block;
        vertical-align: middle;
        width: 2rem;
        height: 1.5rem;
        margin-top: -3px;
    }

    .blur {
        display: inline-block;
        width: 2rem;
        height: 1.5rem;
        filter: blur(1px);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        vertical-align: middle;
        margin-top: -3px;
    }

    .sign-up-message {
        font-family: Arial, Helvetica, sans-serif;

        .sign-up-button {
            color: $intellyq-main;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                color: #0b6cc7;
            }
        }
    }
}
