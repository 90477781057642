@import '../constants.scss';

.HomePage {
    position: relative;
    height: 100%;

    div.ui.container.value-props {
        margin-top: 2rem;
        display: flex;
        position: relative;
        width: 100%;

        h2 {
            margin-left: auto;
            margin-right: auto;
            font-weight: 600;
        }
    }
}