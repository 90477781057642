@import '../constants.scss';

div.ui.container.FiltersBar {
    width: 20%;
    border: $intellyq-border-light;
    box-shadow: $intellyq-box-shadow;
    position: absolute;
    top: 7rem;
    left: 5%;
    border-radius: 5px;
    padding: 20px;

    @media screen and (max-width: 1500px) {
        width: 25%;
    }

    @media screen and (max-width: 800px) {
        display: none;
    }

    div.ui.container.filters-segment {
        margin-bottom: 2rem;

        h3.filters-header {
            font-size: 1.1rem;
            color: $intellyq-font-color;
            font-family: Arial, Helvetica, sans-serif;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }
        }

        .year-filter-label {
            float: right;
            margin-top: -1.3rem;
            color: $intellyq-font-color-light-grey;
            font-family: Arial, Helvetica, sans-serif;
        }

        div.rc-slider.year-filter {
            margin-top: 1rem;

            div.rc-slider-track.rc-slider-track-1 {
                background-color: $intellyq-main;
            }

            div.rc-slider-handle.rc-slider-handle-1,
            div.rc-slider-handle.rc-slider-handle-2 {
                border: 2px solid $intellyq-main;
            }
        }

        .with-answers-toggle {
            float: right;
            margin-top: -1.3rem;
        }
    }
}
