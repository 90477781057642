@import '../constants.scss';

.QuestionList {
    display: flex;
    flex-direction: column;

    @include apply-to-mobile() {
        padding-bottom: 15rem;
    }

    div.ui.pagination.question-pagination {
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        border: none;
    }
}