@import '../constants.scss';

.ui.card.QuestionCard {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding: 1rem;
    padding-bottom: 4rem;
    border: $intellyq-border-light;
    box-shadow: $intellyq-box-shadow;

    @media screen and (max-width: 600px) {
        width: 95%;
    }

    &:hover {
        z-index: 1 !important;
    }
}

.QuestionCard {
    color: $intellyq-font-color;
    text-align: left;

    &.ui.card:hover {
        color: inherit;
        transform: translateY(0px);
        border: $intellyq-border-light;
        box-shadow: $intellyq-box-shadow;
    }

    &.clickable {
        cursor: pointer;
    }

    .question {
        font-family: Arial, Helvetica, sans-serif;
        font-size: $intellyq-medium-font-size;
        font-weight: 600;
        color: $intellyq-font-color;
        margin-bottom: 1rem;
        margin-top: 15px;
        margin-left: 0.5rem;

        @media screen and (max-width: 1400px) {
            font-size: $intellyq-small-font-size;
        }

        @media screen and (max-width: 730px) {
            font-size: $intellyq-very-small-font-size;
        }
    }

    .description {
        margin-left: 1.5rem;

        .answer {
            margin-bottom: 8px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.1rem;
            color: $intellyq-font-color;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }

            &.last-answer {
                margin-bottom: 20px;
            }
        }
    }

    .card-details-top {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        width: 99%;

        .main-details {
            display: flex;
        }

        .card-detail {
            font-family: Arial, Helvetica, sans-serif;
            color: $intellyq-font-color-light-grey;
            margin-right: 10px;

            @media screen and (max-width: 730px) {
                font-size: 0.8rem;
            }

            &.additional-details {
                margin-right: 0;

                @media screen and (max-width: 518px) {
                    display: none;
                }
            }

            .university-icon,
            .course-icon,
            .subtopic-icon {
                margin-right: 5px;
                margin-left: 5px;
                vertical-align: text-top;
                
                svg#Capa_1, svg#Layer_1 {
                    height: 1rem;
                    width: 1rem;
                    fill: $intellyq-main;
                }
            }
        }
    }

    .card-details-bottom {
        color: $intellyq-font-color-light-grey;
        position: absolute;
        font-family: Arial, Helvetica, sans-serif;
        bottom: 1rem;
        left: 1.5rem;

        @media screen and (max-width: 730px) {
            font-size: 0.9rem;
        }

        span.bottom-details-seperator {
            font-size: 2.5rem;
            display: inline-block;
            vertical-align: middle;
            margin-left: 2px;
            margin-right: 2px;
        }
    }
}
