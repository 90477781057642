@import '../constants.scss';

div.ui.container.TestPackPage {
    width: 100%;
    height: calc(100vh - 4.5rem);
    margin-left: 0 !important;
    margin-right: 0 !important;

    div.ui.container.InfoBar {
        width: 20%;
        border: $intellyq-border-light;
        box-shadow: $intellyq-box-shadow;
        position: absolute;
        top: 7rem;
        left: 10%;
        border-radius: 5px;
        padding: 20px;

        @media screen and (max-width: 1500px) {
            width: 25%;
            left: 5%;
        }

        @media screen and (max-width: 1232px) {
            height: 42rem;
        }

        @media screen and (max-width: 1081px) {
            height: 45rem;
        }

        @media screen and (max-width: 800px) {
            display: none;
        }

        .test-pack-title {
            font-size: $intellyq-large-font-size;
            margin-bottom: 1em;
        }

        div.ui.container.info-segment {
            margin-bottom: 2rem;

            h3.info-header {
                font-size: 1.1rem;
                color: $intellyq-font-color;
                font-family: Arial, Helvetica, sans-serif;

                @media screen and (max-width: 1400px) {
                    font-size: 1rem;
                }
            }
        }
    }

    .QuestionsBar {
        width: 70%;
        max-height: calc(100vh - 7rem);
        position: absolute;
        transition: opacity 0.2s ease-in;
        top: 7rem;
        left: 30%;
        overflow: scroll;
        padding-right: 5rem;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 1500px) {
            width: 82%;
            left: 25%;
        }

        @media screen and (max-width: 800px) {
            width: 100%;
            left: 0;
            padding-right: 0;
            max-width: 100%;
            overflow-x: hidden;
        }
    }

    .Toastify {
        .Toastify__toast-container.Toastify__toast-container--top-center {
            .Toastify__toast.Toastify__toast--success {
                background-color: white;

                .Toastify__toast-body {
                    font-family: Arial, Helvetica, sans-serif;
                    color: $intellyq-font-color;
                    margin-left: 1rem;
                }

                button {
                    color: $intellyq-font-color;
                }
            }
        }
    }

    .loader-container {
        height: 80vh;
        padding-top: 30vh;
    }

    .question-not-found {
        margin-top: 10rem;
        margin-bottom: 10rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        text-align: center;

        @include apply-to-mobile() {
            margin-top: 5rem;
            margin-bottom: 5rem;
            padding: 0 1em;
            font-size: 1rem;
        }

        .not-found-icon {
            margin-bottom: 2rem;

            svg {
                height: 16rem;

                @include apply-to-mobile() {
                    height: 12rem;
                    width: 12rem;
                }
            }
        }

        .browse-link {
            margin-top: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            display: block;
        }
    }
}
