@import '../../constants.scss';

.TextFilter {
    margin-bottom: 0.8rem;

    div.ui.input {
        width: 95%;

        input {
            border: none;
            border-bottom: 2px solid $intellyq-grey-dark;
            border-radius: 0;
            padding-left: 0;
            font-size: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            padding-bottom: 0.5rem;
        }
    }
}