@import '../constants.scss';

.EmailConfirmedPage {
    margin-top: 20vh;
    width: 40rem;
    background-color: $intellyq-white;
    margin-left: auto;
    margin-right: auto;
    z-index: 6;
    text-align: center;

    a.continue-home-button {
        font-family: Arial, Helvetica, sans-serif;
        width: 90%;
        border-radius: 2px;
        background-color: $intellyq-main;
        color: white;
        font-size: 1.15rem;
        padding: 1rem 5rem;
        transition: $intellyq-transition;

        &:hover {
            background-color: #1571c7;
        }
    }

    .error-message, .success-message {
        margin-top: 4rem;
        margin-bottom: 4rem;
        font-size: $intellyq-large-font-size;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
    }

    .error-message {
        font-size: $intellyq-large-font-size;
        color: $intellyq-red;
        text-align: center;
    }

    .success-message {
        font-size: $intellyq-large-font-size;
        text-align: center;
    }

    @include apply-to-mobile() {
        width: 100%;
        height: 100%;

        .ui.small.header {
            font-size: $intellyq-large-font-size;
        }
    }
}