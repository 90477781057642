@import '../../constants.scss';

.ProgressBar {
    display: flex;
    margin-bottom: 4rem;
    text-align: center;
    margin-top: 2rem;

    @include apply-to-mobile() {
        margin-bottom: 2rem;
    }

    .progress-bar-segment {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        align-items: center;

        .major-progress-bar-connectors {
            width: inherit;
            height: 5px;
            background-color: $intellyq-grey-light;
            position: absolute;
            top: 1.2rem;
            right: 54%;
            z-index: 0;

            @media screen and (min-width: 1300px) {
                right: 50%;
            }

            &.active {
                background-color: $intellyq-main;
            }
        }

        .progress-bar-span {
            font-family: Arial, Helvetica, sans-serif;
            border: 1px solid $intellyq-grey-light;
            background-color: $intellyq-grey-light;
            color: $intellyq-grey-dark;
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 2rem;
            font-weight: 500;
            font-size: 1.3rem;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            padding-top: 8px;
            margin-bottom: 8px;
            z-index: 2;

            &.active {
                background-color: white;
                border: 1px solid $intellyq-main;
                color: $intellyq-font-color;
            }
        }

        .segment-label-span {
            font-family: Arial, Helvetica, sans-serif;

            @include apply-to-mobile() {
                display: none;
            }
        }
    }
}