@import '../../constants.scss';

div.ui.container.QuestionAnalytics {
    h3.header {
        font-weight: 600;
        font-size: $intellyq-semi-large-font-size;
        color: $intellyq-font-color;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 3rem;

        @include apply-to-mobile() {
            font-size: $intellyq-medium-font-size;
        }
    }

    .coming-soon-container {
        text-align: center;

        .illustration {
            height: 16rem;

            @media screen and (max-width: 580px) {
                height: 13rem;
            }

            @media screen and (max-width: 430px) {
                height: 9rem;
            }
        }

        .coming-soon-message {
            margin-top: 2rem;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
}
