@import '../constants.scss';

.FiltersPage {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: $intellyq-semi-large-font-size;
    padding: 4rem 1rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 7;
    overflow: scroll;

    // Disables highlighting text on filters
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome and Opera */

    @media screen and (min-width: 801px) {
        display: none;
    }

    &.slide-in-from-bottom-enter {
        transform: translateY(100%);

        &.slide-in-from-bottom-enter-active {
            transition: transform 0.3s linear;
            transform: translateY(0);
        }
    }

    &.slide-in-from-bottom-exit {
        transform: translateY(0);

        &.slide-in-from-bottom-exit-active {
            transition: transform 0.3s linear;
            transform: translateY(100%);
        }
    }

    div.ui.container.filters-segment {
        margin-bottom: 2rem;

        h3.filters-header {
            font-size: 1.1rem;
            color: $intellyq-font-color;
            font-family: Arial, Helvetica, sans-serif;

            @media screen and (max-width: 1400px) {
                font-size: 1rem;
            }
        }

        .year-filter-label {
            float: right;
            margin-top: -1.3rem;
            color: $intellyq-font-color-light-grey;
            font-family: Arial, Helvetica, sans-serif;
        }

        div.rc-slider.year-filter {
            margin-top: 1rem;

            div.rc-slider-track.rc-slider-track-1 {
                background-color: $intellyq-main;
            }

            div.rc-slider-handle.rc-slider-handle-1,
            div.rc-slider-handle.rc-slider-handle-2 {
                border: 2px solid $intellyq-main;
            }
        }

        .with-answers-toggle {
            float: right;
            margin-top: -1.3rem;
        }
    }

    div.ui.container.filters-page-bottombar {
        background-color: white;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 2rem;
        margin-bottom: 3rem;
        text-align: center;

        button.ui.button.show-questions {
            font-family: Arial, Helvetica, sans-serif;
            background-color: $intellyq-main;
            color: white;
            padding: 1.1rem 3rem;
        }
    }
}