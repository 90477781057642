@import '../constants.scss';

.UpgradeForm {
    width: 100%;

    @include apply-to-mobile() {
        padding-bottom: 8rem;
    }

    .checkout-container {
        display: flex;

        @include apply-to-mobile() {
            flex-direction: column;
        }

        .mobile-details-dropdown-button {
            width: 100%;
            height: 5rem;
            background-color: $intellyq-grey-lighter;
            padding: 2rem 3rem;
            font-family: Arial, Helvetica, sans-serif;
            cursor: pointer;

            @media screen and (min-width: 801px) {
                display: none;
            }

            .total-price {
                font-family: Arial, Helvetica, sans-serif;
                float: right;
                font-weight: 600;
                font-size: 1.2rem;
            }

            .show-details-chevron {
                float: right;
                margin-left: 1.5rem;
                transform: rotate(90deg);
                transition: 304ms ease-out transform;
                height: 1rem;

                svg#Capa_1 {
                    fill: $intellyq-font-color-light-grey;
                }

                &.up {
                    transform: rotate(270deg);
                    height: 1rem;
                }
            }
        }

        .checkout-details {
            background-color: $intellyq-grey-lighter;
            width: 40%;
            padding-top: 6rem;
            padding-left: 6rem;
            height: 100vh;

            &.desktop {
                @include apply-to-mobile() {
                    display: none;
                }
            }

            &.mobile {
                width: 100%;
                height: 40rem;
                padding-top: 3rem;

                @media screen and (min-width: 801px) {
                    display: none !important;
                }
            }

            div.ui.fluid.selection.dropdown.plan-dropdown {
                width: 70%;
                margin-bottom: 4px;

                div.text {
                    font-family: Arial, Helvetica, sans-serif;
                }

                div.visible.menu.transition {
                    div.item {
                        span.text {
                            font-family: Arial, Helvetica, sans-serif;
                        }
                    }
                }
            }

            .plan-renewal-message {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 0.9rem;
                color: $intellyq-font-color-light-grey;
                width: 70%;
                margin-bottom: 2rem;
            }

            .plan-message {
                font-family: Arial, Helvetica, sans-serif;
                color: $intellyq-font-color-light-grey;
                margin-bottom: 2.5rem;
                margin-top: 8px;
            }

            .total-message {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
                font-size: 1.1rem;
                width: 70%;
                margin-bottom: 1.5rem;
                margin-top: 1.5rem;

                .total-price {
                    font-family: Arial, Helvetica, sans-serif;
                    float: right;
                }
            }

            .payment-divider {
                width: 70%;
                margin-left: 0;
                height: 2px;
                border: none;
                background-color: #d4d4d4;
            }

            .stripe-badge-container {
                margin-top: 1.5rem;
                width: 70%;
                position: relative;

                .stripe-badge {
                    width: 120px;
                    position: absolute;
                    right: 0;
                }
            }

            button.ui.button.referral-button {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1rem;
                background: transparent;
                border: none;
                padding-left: 0;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }

            div.ui.input.slide.down.visible.transition {
                margin-bottom: 1.5rem;

                input {
                    font-family: Arial, Helvetica, sans-serif;
                    background-color: white;
                    border: 1px solid #dddddd;
                    font-size: 1rem;
                    width: 60%;
                }
            }
        }

        .intellyq-checkout-logo-container {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;

            &.desktop {
                @include apply-to-mobile() {
                    display: none;
                }
            }

            &.mobile {
                margin-top: 4rem;

                @media screen and (min-width: 801px) {
                    display: none;
                }
            }

            .intellyq-checkout-logo-link {
                .intellyq-checkout-logo {
                    height: 2.5rem;
                }
            }
        }

        .checkout-form {
            width: 60%;
            margin-top: 5rem;
            padding: 0 6rem;

            @include apply-to-mobile() {
                width: 100%;
                margin-top: 0;
                padding: 0 1rem;
            }

            .payment-steps {
                width: 100%;
                text-align: center;
                margin-bottom: 3.5rem;
                margin-top: 2.5rem;

                a.choose-plan-link,
                a.payment-method-link {
                    font-family: Arial, Helvetica, sans-serif;
                    display: inline-block;
                    width: 50%;
                    color: $intellyq-font-color-light-grey;
                    font-weight: 600;
                    font-size: 1.1rem;
                    padding-bottom: 1rem;
                    border-bottom: 2px solid $intellyq-grey-light;
                }

                a.payment-method-link {
                    color: $intellyq-font-color-dark-grey;
                    font-weight: 600;
                    border-bottom: 2px solid $intellyq-main;
                }
            }

            .checkout {
                .StripeElement,
                div.expiry-input,
                div.postal-input {
                    border: 1px solid #dddddd;
                    padding: 15px 15px;
                    border-radius: 5px;
                    margin-bottom: 2rem;

                    &.StripeElement--focus {
                        border: 1px solid $intellyq-main;
                    }
                }

                button.submit-button {
                    background-color: $intellyq-main;
                    font-size: 1rem;
                    font-weight: 600;
                    padding: 12px 50px;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    float: right;
                    transition: $intellyq-transition;

                    &:hover {
                        background-color: #1571c7;
                    }
                }

                .error-message {
                    font-family: Arial, Helvetica, sans-serif;
                    color: red;
                }

                div.ui.input.name-input {
                    margin-bottom: 2rem;
                    width: 100%;

                    input {
                        border: 1px solid #dddddd;
                        padding: 15px 15px;
                        border-radius: 5px;
                        color: $intellyq-font-color-dark-grey;
                        font-size: 14px;
                        font-family: sans-serif;

                        &:focus {
                            border: 1px solid $intellyq-main;
                        }

                        &::placeholder {
                            color: $intellyq-font-color-dark-grey;
                        }
                    }
                }

                div.additional-card-details {
                    display: flex;

                    div.StripeElement {
                        width: 30%;

                        @include apply-to-mobile() {
                            width: 48%;
                        }
                    }

                    div.expiry-input {
                        margin-right: 1em;
                    }
                }
            }
        }
    }
}

div.ui.container.payment-success-container {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .payment-success-message {
        margin-top: 10rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        text-align: center;

        .plan-name {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            color: $intellyq-main;
        }

        .payment-success-icon {
            margin-bottom: 1rem;

            svg#Capa_1 {
                fill: $intellyq-main;
                height: 4rem;
                width: 4rem;
            }
        }

        .browse-link {
            margin-top: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            display: block;
        }

        .login-button {
            margin-top: 10px;
            font-family: Arial, Helvetica, sans-serif;
            display: block;
            margin-left: auto;
            margin-right: auto;
            font-size: $intellyq-tiny-font-size;
            cursor: pointer;
            background-color: $intellyq-main;
            border: none;
            border-radius: 4px;
            color: white;
            transition: $intellyq-transition;
            padding: 12px 3rem;

            &:hover {
                background-color: #1571c7;
                color: white;
            }
        }
    }
}
