@import '../constants.scss';

div.ui.container.Sitemap {
    padding-top: 2rem;
    text-align: center;
    width: 100%;

    div.ui.container.sitemap-heading {
        margin-bottom: 2rem;

        h1 {
            font-family: Arial, Helvetica, sans-serif;
            color: $intellyq-main;
        }
    }

    h2 {
        font-family: Arial, Helvetica, sans-serif;
        color: $intellyq-font-color;
        margin-bottom: 5px;
    }

    ul {
        margin-bottom: 1rem;

        li {
            margin-bottom: 5px;

            a {
                font-family: Arial, Helvetica, sans-serif;
                color: $intellyq-main;
            }
        }
    }

    ul.question-links {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        text-align: left;

        li {
            margin-bottom: 10px;
        }
    }
}