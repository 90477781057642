@import '../../constants.scss';

.SuccessPage {
    text-align: center;

    div.ui.container.success-container {
        padding-top: 4rem;

        h4 {
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-semi-massive-font-size;
            margin-bottom: 1rem;

            @include apply-to-mobile() {
                font-size: $intellyq-semi-large-font-size;
            }
        }

        h5 {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-size: $intellyq-medium-font-size;
            margin-bottom: 1rem;

            @include apply-to-mobile() {
                font-size: $intellyq-tiny-font-size;
            }
        }

        div.links {
            a {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
            }
        }
    }
}