@import '../../constants.scss';

.TestTypeFilter {
    div.ui.borderless.menu {
        border: none;
        box-shadow: none;
        width: 100%;
        flex-wrap: wrap;

        div.item {
            div.ui.checkbox {
                label {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1rem;
                }
            }
        }
    }
}