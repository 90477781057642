@import '../../constants.scss';

.Header {
    position: relative;
    width: 100%;
    display: flex;
    padding: 10vh 8em;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        padding: 10vh 2em;
    }

    @media screen and (max-width: 400px) {
        padding: 7vh 2em;
        padding-bottom: 2em;
    }

    .content {
        z-index: 1;
        width: 50%;

        @media screen and (max-width: 1130px) {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (max-width: 1000px) {
            width: 100%;
        }

        .site-title {
            font-size: $intellyq-very-massive-font-size;
            font-family: Arial, Helvetica, sans-serif;
            width: 90%;
    
            @media screen and (max-width: 600px) {
                font-size: $intellyq-huge-font-size;
            }
    
            .site-title-highlight {
                font-family: Arial, Helvetica, sans-serif;
                color: $intellyq-main;
            }
        }
    
        .catchphrase {
            font-weight: 500;
            font-family: Arial, Helvetica, sans-serif;
            margin-top: 1rem;
            font-size: $intellyq-large-font-size;
    
            @media screen and (max-width: 600px) {
                font-size: $intellyq-very-small-font-size;
            }
        }

        .cta-container {
            display: flex;
            justify-content: space-between;
            margin-top: 2em;

            @media screen and (max-width: 600px) {
                flex-direction: column;
            }

            .cta-item {
                background-color: #eef2fe;
                width: 10em;
                height: 2.8em;
                text-align: center;
                display: flex;
                border-radius: 100px;
                justify-content: center;
                align-items: center;
                color: #265bf6;
                cursor: pointer;
                transition: $intellyq-transition;
                font-weight: 500;

                &:hover {
                    background-color: #93adfb66;
                }

                @media screen and (max-width: 600px) {
                    margin: 0.5em 0;
                    width: 100%;
                }
            }
        }
    }

    .header-image-container {
        width: 45%;

        @media screen and (max-width: 1130px) {
            display: none;
        }

        .header-image {
            display: block;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.15);
            cursor: pointer;
        }
    }
}
