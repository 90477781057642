@import '../../constants.scss';

.valueproposition-sectionwrap {
    display: flex;
    flex-direction: column;
    padding: 0 8em;
    padding-top: 4em;
    padding-bottom: 120px;
    width: 100%;

    @include apply-to-mobile() {
        margin-top: 2rem;
        padding: 5px;
        padding-top: 2em;
        padding-bottom: 120px;
    }

    @media screen and (max-width: 600px) {
        padding-bottom: 45px;
    }

    h1.valueprops-intro-header {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: $intellyq-font-color;
        padding: 20px;
        font-size: 1.5rem;
        padding-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 7rem;
        padding-bottom: 1rem;
        border: 4px dotted #ff980080;

        @media screen and (max-width: 600px) {
            text-align: center;
            font-weight: 600;
            font-size: 1.5rem;
            margin-bottom: 3rem;
        }
    }

    .value {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12rem;

        &.contribute-earn {
            margin-bottom: 5rem;
        }

        @include apply-to-mobile() {
            flex-direction: column;
            margin-left: 0 !important;
            width: 100% !important;
            margin-bottom: 6rem;
        }

        .value-block {
            @include apply-to-mobile() {
                margin-left: auto;
                margin-right: auto;
                width: 100% !important;
            }
        }

        .value-block-photo {
            -ms-flex-item-align: center;
            align-self: center;
            text-align: center;
            padding: 24px;
            width: 45%;
            position: relative;

            &.desktop-only {
                @include apply-to-mobile() {
                    display: none;
                }
            }

            &.mobile-only {
                @media screen and (min-width: 800px) {
                    display: none;
                }
            }

            img {
                height: 18rem;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
        }

        .value-block-text {
            position: relative;
            padding: 2em;
            width: 48%;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
            padding-top: 2.8rem;

            @include apply-to-mobile() {
                margin-bottom: 4rem;
                width: 100% !important;
            }

            @media screen and (max-width: 321px) {
                height: 27rem;
            }

            .value-header {
                padding-bottom: 15px;
                margin: 0;
                font-size: $intellyq-semi-massive-font-size;
                display: inline-block;
                line-height: 1.3em;
                color: #303339;
                font-family: Arial, Helvetica, sans-serif;
                font-style: normal;

                @include apply-to-mobile() {
                    font-size: 1.5rem;
                }
            }

            .value-paragraph {
                color: $intellyq-font-color;
                line-height: 1.7;
                font-size: $intellyq-small-font-size;
                font-family: Arial, Helvetica, sans-serif;
                margin-bottom: 1em;

                @include apply-to-mobile() {
                    width: 100%;
                    margin-bottom: 4rem;
                }
            }

            .value-prop-list {
                list-style-type: disc;
                margin-left: 25px;
                font-size: $intellyq-small-font-size;
                margin-bottom: 10px;
            }
        }
    }
}
