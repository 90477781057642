@import '../constants.scss';

div.ui.container.AccountPage {
    width: 100% !important;
    padding-bottom: 4rem;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .account-content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;

        div.ui.pointing.secondary.menu {
            a {
                font-family: Arial, Helvetica, sans-serif;
            }
        }
    }

    .error-message {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.1rem;
        color: red;
        margin-top: 2rem;
    }

    .success-message {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 1.1rem;
        color: green;
        margin-top: 2rem;

        span {
            margin-right: 0.5rem;
            vertical-align: middle;

            svg#Capa_1 {
                height: 1.2rem;
                width: 1.2rem;
                fill: green;
            }
        }
    }
}