@import '../constants.scss';

.TestGenerator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 34rem;
    background-color: $intellyq-white;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;
    border-radius: 5px;
    padding-bottom: 3rem;

    .close-icon {
        fill: $intellyq-black;
        width: 24px;
        float: right;
        margin-right: 1.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        transition: $intellyq-transition;

        &:hover {
            fill: $intellyq-main;
        }
    }

    .content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        .ui.small.header {
            margin-top: 3.5rem;
            font-size: $intellyq-big-font-size;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            text-align: center;
        }

        .ui.text.container.simulator-message {
            text-align: center;
            font-family: Arial, Helvetica, sans-serif;
            width: 100%;
            font-size: $intellyq-tiny-font-size;
            color: $intellyq-font-color;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-bottom: 2rem;
        }

        .criteria-header {
            font-family: Arial, Helvetica, sans-serif;
            color: $intellyq-font-color;
            text-align: left;
            margin-bottom: 10px;
        }

        div.ui.fluid.selection.dropdown.course-dropdown {
            margin-bottom: 1.5rem;

            div.text {
                font-family: Arial, Helvetica, sans-serif;
            }

            div.visible.menu.transition {
                div.item {
                    span.text {
                        font-family: Arial, Helvetica, sans-serif;
                    }
                }
            }
        }

        .TestTypeFilter {
            padding-left: 4rem;
            margin-bottom: 2rem;

            @media screen and (max-width: 800px) {
                padding-left: 0rem;
            }

            div.ui.borderless.menu {
                div.item {
                    display: flex;
                    flex-direction: column;

                    .number-of-questions {
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 0.95rem;
                        margin-top: 5px;
                    }
                }
            }
        }

        .ui.button.generate-test-button {
            font-family: Arial, Helvetica, sans-serif;
            width: 85%;
            background-color: rgba(0, 119, 154, 0.3);
            color: white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            transition: $intellyq-transition;
            border-radius: 5px;
            font-size: 1.05rem;

            &.active {
                background-color: $intellyq-main;
                cursor: pointer;

                &:hover {
                    background-color: #1571c7;
                }
            }
        }

        .error-message {
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-tiny-font-size;
            margin-top: 2rem;
            color: $intellyq-red;
            margin-bottom: 1rem;
        }
    }

    @include apply-to-mobile() {
        width: 100%;
        height: 100%;
    }
}
