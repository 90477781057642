@import '../../constants.scss';

div.ui.container.SimilarQuestions {
    position: relative;

    @include apply-to-mobile() {
        padding-left: 0;
    }

    h3.header {
        font-weight: 600;
        font-size: $intellyq-semi-large-font-size;
        color: $intellyq-font-color;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 2rem;
    }

    .item-slider {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;

        a.ui.card.QuestionCard.clickable {
            min-width: 54rem;
            margin-left: 0;
            margin-right: 2rem;
            margin-top: 0;
            box-shadow: none;
            border: 1px solid $intellyq-font-color-lighter-grey;

            @include apply-to-mobile() {
                min-width: 40rem;
            }

            @media screen and (max-width: 600px) {
                min-width: 100%;
            }
        }
    }

    img.scroll-button {
        position: absolute;
        top: 16rem;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 35px;
        padding: 0.8rem 1.2rem;
        cursor: pointer;
        box-shadow: 0 0px 14px rgba(50,50,93,0.5);
        z-index: 4;
        height: 1rem;

        @include apply-to-mobile() {
            display: none;
        }
    }

    div.scroll-button.hidden {
        display: none;
    }

    div.scroll-button.left {
        transform: rotate(180deg);
        left: 65px;
        margin-top: 5px;
    }

    div.scroll-button.right {
        right: 55px;
        margin-top: 5px;
    }
}
