@import '../constants.scss';

.TopBar {
    background-color: transparent;
    z-index: 3;
    height: 4.5rem;
    position: relative;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);

    .chevron {
        margin-left: 0.75rem;
        margin-top: 5px;
        fill: $intellyq-font-color-light-grey;
        width: 9px;
        transform: rotate(90deg);
        transition: 304ms ease-out transform;

        &.up {
            transform: rotate(270deg);
        }
    }

    .expanded {
        background-color: white;
    }

    .logo-desktop {
        @include apply-to-mobile() {
            display: none;
        }

        .intellyq-logo {
            margin-left: 1.5rem;
            margin-top: 1rem;
            z-index: 6;
            height: 2.5rem;
        }
    }

    .logo-container {
        display: flex;
        z-index: 6;
        align-items: center;
        cursor: pointer;

        .intellyq-logo {
            margin-left: 1.5rem;
            margin-top: 1rem;
            z-index: 6;
            height: 2.5rem;
        }

        @media screen and (min-width: 801px) {
            display: none;
        }
    }

    .nav-container {
        position: absolute;
        right: 2.5rem;
        top: 1.1rem;

        @include apply-to-mobile() {
            display: none;
        }

        .nav-item {
            display: inline-block;
            margin-left: 1.5rem;
            cursor: pointer;
            transition: $intellyq-transition;
            font-size: $intellyq-tiny-font-size;
            color: $intellyq-font-color;
            font-family: Arial, Helvetica, sans-serif;

            a {
                color: $intellyq-font-color;
            }

            &:hover {
                color: $intellyq-font-color;
            }

            &.signup {
                background-color: #0458a7;
                padding: 8px 16px;
                border-radius: 100px;
                color: white;

                &:hover {
                    background-color: #1571c7;
                }
            }

            .upgrade-link {
                display: inline-flex;
                color: $intellyq-main !important;
                font-weight: 600;

                .bolt-icon {
                    width: 12px;
                    margin-right: 4px;
                    fill: $intellyq-main;
                }
            }


            div.ui.compact.menu {
                background: none;
                border: none;
                box-shadow: none;
                height: 4.5rem;

                div.ui.item.simple.dropdown {
                    &:hover {
                        background: none;
                        border: none;
                        box-shadow: none;
                    }

                    .user-avatar {
                        svg#Capa_1 {
                            height: 1.5rem;
                        }
                    }

                    div.text {
                        display: none;
                    }

                    div.left.menu.transition {
                        div.selected.item,
                        div.item,
                        div.active.selected.item {
                            background: none !important;
                            font-weight: 500 !important;

                            &:hover {
                                background: rgba(0, 0, 0, 0.05) !important;
                            }

                            span.text {
                                font-family: Arial, Helvetica, sans-serif;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .nav-item.logged-in-menu {
            margin-top: -1.1rem;
            margin-left: 0.5rem;
        }
    }

    .SearchBar.ui.search {
        position: absolute;
        top: 0.5rem;
        left: 11rem;

        @include apply-to-mobile() {
            left: 6.5rem;
            width: 73%;
        }

        div.results.transition.visible {
            .category {
                .name {
                    .category {
                        display: flex;

                        .category-label {
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}
