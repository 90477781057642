@import '../constants.scss';

.PrivacyPolicy {
    width: 100%;
    padding-top: 4rem;

    .ui.container.content {
        margin: 2rem 0;
        font-family: Arial, Helvetica, sans-serif;
        width: 80% !important;
        max-width: none !important;
        margin-left: auto !important;
        margin-right: auto !important;

        p {
            margin: 1rem 0;
            font-family: Arial, Helvetica, sans-serif;
        }

        li {
            list-style-type: disc;
            margin-left: 1.5rem;
            font-family: Arial, Helvetica, sans-serif;
        }

        h3,
        h4 {
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    .intellyq-logo-link {
        width: 10%;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 500px) {
            width: 25%;
        }

        .intellyq-logo {
            height: 2.5rem;
        }
    }
}
