@import '../constants.scss';

.FloatingMenuPill {
    position: absolute;
    bottom: 1.5rem;
    width: 12rem;
    z-index: 1;
    border-radius: 2em !important;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    justify-content: center;

    @media screen and (min-width: 801px) {
        display: none !important;
    }

    .item {
        border: none;

        span.filter-pill-text {
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-tiny-font-size;
        }
    }

    .item::before {
        display: none;
    }

    .mobile-icon {
        height: 16px;
        width: 16px;
        margin-right: 8px;

        svg#Capa_1 {
            fill: $intellyq-grey-darkest;
        }
    }
}
