@import '../../constants.scss';

div.ui.container.DocumentInfoPicker {
    padding-left: 4rem;
    width: 70%;

    @media screen and (min-width: 1300px) {
        width: 65%;
    }

    @media screen and (min-width: 1500px) {
        width: 55%;
    }

    @media screen and (max-width: 1175px) {
        width: 80%;
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
    }

    h2.document-info-header {
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 4rem;
    }

    .input-row {
        display: flex;
        margin-bottom: 1rem;

        @media screen and (max-width: 865px) {
            display: initial;
        }

        .input-section {
            margin-right: 5rem;

            h5 {
                font-family: Arial, Helvetica, sans-serif;
                font-size: $intellyq-small-font-size;
                margin-bottom: 0.8rem;
                color: $intellyq-font-color-light-grey;
            }

            div.input {
                margin-bottom: 2rem;
                width: 25rem;

                @media screen and (max-width: 500px) {
                    width: 100%;
                }
            }
        }
    }

    .one-contribution-message {
        font-family: Arial, Helvetica, sans-serif;
        color: $intellyq-font-color-light-grey;
        font-size: 0.9rem;
    }
}