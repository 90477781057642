@import '../constants.scss';

div.ui.container.TestSimulatorPage {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .content-container {
        display: flex;
        position: relative;

        .show-menu-bar-button-mobile {
            position: absolute;
            width: 100%;
            top: 5.95rem;
            background-color: $intellyq-grey-light;
            padding-left: 1rem;
            padding-top: 10px;
            padding-bottom: 10px;
            font-family: Arial, Helvetica, sans-serif;
            z-index: 2;

            &.no-tests {
                top: 0rem;
            }

            .show-tests-chevron {
                float: right;
                margin-right: 1.5rem;
                transform: rotate(90deg);
                transition: 304ms ease-out transform;
                height: 1rem;

                svg#Capa_1 {
                    fill: $intellyq-font-color-light-grey;
                }

                &.up {
                    transform: rotate(270deg);
                    height: 1rem;
                }
            }

            @media screen and (min-width: 851px) {
                display: none;
            }

            @media screen and (max-width: 600px) {
                top: 3.95rem;
            }
        }

        .test-menu-bar {
            width: 18%;
            height: calc(100vh - 4.5rem);
            padding-top: 30px;
            border-right: 1px solid $intellyq-grey-lighter;
            overflow: scroll;

            &.desktop {
                @media screen and (max-width: 850px) {
                    display: none;
                }
            }

            .test-menu-header-container {
                text-align: center;

                .test-menu-header {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: $intellyq-font-color-light-grey;
                }
            }

            .test-list {
                margin-top: 1.5rem;

                .new-test-container {
                    background-color: $intellyq-grey-lighter;

                    .new-test-button {
                        width: 100%;
                        height: 4.5rem;
                        font-family: Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        font-size: 1.1rem;
                        background: none;
                        color: $intellyq-main;
                        transition: $intellyq-transition;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            color: #1571c7;
                        }
                    }
                }

                .test-info {
                    border-top: 1px solid $intellyq-grey-lighter;
                    border-bottom: 1px solid $intellyq-grey-lighter;
                    font-family: Arial, Helvetica, sans-serif;
                    padding-left: 1.5rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    font-size: 1rem;
                    color: $intellyq-font-color-lighter-grey;
                    font-weight: 600;
                    cursor: pointer;
                    transition: $intellyq-transition;
                    position: relative;

                    &:hover {
                        background-color: $intellyq-grey-lighter;
                    }

                    &.active {
                        background-color: $intellyq-main;
                        color: white;

                        &:hover {
                            background-color: $intellyq-main;
                        }
                    }

                    .test-date {
                        font-family: Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        margin-top: 5px;
                    }

                    .lock-icon {
                        position: absolute;
                        right: 5px;
                        bottom: 5px;
                        height: 1.1rem;
                    }
                }
            }

            &.mobile {
                width: 100%;
                height: calc(100vh - 13rem);
                position: absolute;
                z-index: 2;
                background-color: white;
                top: 8.65rem;

                &.no-tests {
                    top: 3rem;
                }

                @media screen and (max-width: 600px) {
                    height: calc(100vh - 11rem);
                    top: 6.65rem;
                }

                @media screen and (min-width: 851px) {
                    display: none;
                }
            }
        }

        .test-container {
            width: 82%;
            height: calc(100vh - 4.5rem);

            @media screen and (max-width: 850px) {
                width: 100%;
            }

            .noTests {
                text-align: center;
                margin-top: 9rem;
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                .illustration {
                    height: 15rem;

                    @media screen and (max-width: 580px) {
                        height: 15rem;
                    }

                    @media screen and (max-width: 430px) {
                        height: 12rem;
                    }
                }

                .no-tests-message {
                    margin-top: 3rem;
                    font-family: Arial, Helvetica, sans-serif;
                }

                .create-test-button {
                    margin-top: 3rem;
                    background-color: #0458a7;
                    padding: 8px 16px;
                    border-radius: 5px;
                    color: white;
                    cursor: pointer;
                    border: none;

                    &:hover {
                        background-color: #1571c7;
                    }
                }
            }

            .loginMessage {
                text-align: center;
                margin-top: 9rem;
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                .illustration {
                    height: 15rem;

                    @media screen and (max-width: 580px) {
                        height: 15rem;
                    }

                    @media screen and (max-width: 430px) {
                        height: 12rem;
                    }
                }

                .login-message {
                    margin-top: 3rem;
                    font-family: Arial, Helvetica, sans-serif;

                    .login-button {
                        font-family: Arial, Helvetica, sans-serif;
                        color: $intellyq-main;
                        cursor: pointer;

                        &:hover {
                            color: #1571c7;
                        }
                    }
                }
            }
        }
    }
}
