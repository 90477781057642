@import '../constants.scss';

.item-slider {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

div.scroll-button {
    position: absolute;
    top: 16rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 35px;
    padding: 0.8rem 1.2rem;
    cursor: pointer;
    box-shadow: 0 0px 14px rgba(50,50,93,0.5);
    z-index: 4;

    @include apply-to-mobile() {
        display: none;
    }

    svg#Capa_1 {
        height: 1rem;
        width: 1rem;
        fill: $intellyq-font-color;
    }
}

div.scroll-button.hidden {
    display: none;
}

div.scroll-button.left {
    transform: rotate(180deg);
    left: 65px;

    svg#Capa_1 {
        margin-top: 5px;
    }
}

div.scroll-button.right {
    right: 55px;

    svg#Capa_1 {
        margin-top: 5px;
    }
}