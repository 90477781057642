@import '../constants.scss';

.PasswordResetPage {
    margin-top: 20vh;
    width: 40rem;
    background-color: $intellyq-white;
    margin-left: auto;
    margin-right: auto;
    z-index: 6;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;

    .password-validation-message {
        font-family: Arial, Helvetica, sans-serif;
        color: red;
    }

    .page-header {
        margin-top: 4rem;
        font-size: $intellyq-huge-font-size;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
    }

    .ui.text.container.reset-message {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: $intellyq-small-font-size;
        color: $intellyq-font-color;
        padding-left: 2rem;
        width: 100%;
        padding-right: 2rem;
        margin-bottom: 2rem;
    }


    .ui.button.reset-password-button {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        width: 90%;
        border-radius: 2px;
        background-color: $intellyq-main;
        color: white;
        font-size: 1.15rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: $intellyq-transition;

        &:hover {
            background-color: $intellyq-main-dark;
        }
    }

    .ui.input {
        font-size: 1.15rem;
        width: 90%;
        margin-bottom: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        color: $intellyq-font-color;
        border-radius: 2px;
    }

    .ui.input > input {
        font-family: Arial, Helvetica, sans-serif;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &:focus {
            border-color: $intellyq-main;
        }
    }

    .close-icon {
        fill: $intellyq-black;
        width: 24px;
        float: left;
        margin-left: 1.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        transition: $intellyq-transition;

        &:hover {
            fill: $intellyq-main;
        }
    }

    .ui.text.container.switch-message {
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: $intellyq-small-font-size;
        color: $intellyq-font-color;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .error-message {
        font-size: $intellyq-small-font-size;
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: $intellyq-red;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
    }

    .login {
        cursor: pointer;
        color: $intellyq-main;
        transition: $intellyq-transition;
        font-family: Arial, Helvetica, sans-serif;

        &:hover {
            color: $intellyq-main-dark;
        }
    }

    @include apply-to-mobile() {
        width: 100%;
        height: 100%;

        .ui.small.header {
            font-size: $intellyq-big-font-size;
        }
    }
}