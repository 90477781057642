@import '../constants.scss';

div.ui.container.ContributionForm {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;

    div.ui.container.DocumentInfoPicker,
    div.ui.container.DocumentUpload {
        .ui.container.form-button-container {
            //margin left and right importants to overwrite semantic ui
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 3rem;
            margin-bottom: 1rem;
            padding-bottom: 3rem;

            div.back-button {
                font-family: Arial, Helvetica, sans-serif;
                background-color: white;
                color: $intellyq-font-color-light-grey;
                font-size: $intellyq-small-font-size;
                font-weight: 500;
                padding-left: 0;
                padding-right: 0;
                cursor: pointer;
                float: left;

                i.chevron.left.icon {
                    transform: translateY(1px);
                    margin-right: 5px;
                }

                &.hover {
                    background-color: white;
                    color: #484848;
                }
            }

            .next-button {
                font-family: Arial, Helvetica, sans-serif;
                float: right;
                color: white;
                background-color: $intellyq-main;
                padding: 0.8rem 1.5rem 0.8rem 1.5rem;
                font-size: $intellyq-medium-font-size;
                font-weight: 600;
                border-radius: 2px;
                cursor: pointer;
            }

            .next-button-disabled {
                font-family: Arial, Helvetica, sans-serif;
                float: right;
                color: white;
                background-color: rgba(0, 119, 154, 0.3);
                padding: 0.8rem 1.5rem 0.8rem 1.5rem;
                font-weight: 600;
                font-size: $intellyq-medium-font-size;
                border-radius: 3px;
                pointer-events: none;
            }
        }
    }
}