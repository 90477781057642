@import '../constants.scss';

.Login {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 34rem;
    background-color: $intellyq-white;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;
    border-radius: 5px;
    padding-bottom: 2rem;

    .close-icon {
        fill: $intellyq-black;
        width: 24px;
        position: absolute;
        right: 1.5em;
        top: 1.5rem;
        cursor: pointer;
        transition: $intellyq-transition;

        &:hover {
            fill: $intellyq-main;
        }
    }

    .content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        .ui.small.header {
            margin-top: 3.5rem;
            font-size: $intellyq-big-font-size;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            text-align: center;
        }

        .ui.text.container.login-message {
            text-align: center;
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-tiny-font-size;
            color: $intellyq-font-color;
            padding-left: 2rem;
            width: 100%;
            padding-right: 2rem;
            margin-bottom: 2rem;
        }


        .ui.button.signup-login-button {
            font-family: Arial, Helvetica, sans-serif;
            width: 85%;
            border-radius: 2px;
            background-color: $intellyq-main;
            color: white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            transition: $intellyq-transition;
            border-radius: 5px;
            font-size: 1.05rem;
            margin-bottom: 1rem;

            &:hover {
                background-color: #1571c7;
            }
        }

        .ui.input {
            font-size: 1.15rem;
            width: 85%;
            margin-bottom: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            color: $intellyq-font-color;
            border-radius: 2px;
        }

        .ui.input > input {
            font-family: Arial, Helvetica, sans-serif;
            padding-top: 1rem;
            padding-bottom: 1rem;

            &:focus {
                border-color: $intellyq-main;
            }
        }

        .ui.text.container.switch-message {
            text-align: center;
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-tiny-font-size;
            color: $intellyq-font-color-light-grey;
            width: 100%;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        .error-message {
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-small-font-size;
            margin-top: 2rem;
            margin-bottom: 2rem;
            color: $intellyq-red;
        }

        .sign-up {
            font-family: Arial, Helvetica, sans-serif;
            cursor: pointer;
            color: $intellyq-main;
            transition: $intellyq-transition;
            font-weight: 600;

            &:hover {
                color: #1571c7;
            }
        }
    }

    @include apply-to-mobile() {
        width: 100%;
        height: 100%;
    }
}