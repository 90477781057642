@import '../../constants.scss';

div.ui.container.DocumentUpload {
    width: 70%;
    padding-left: 4rem;

    @include apply-to-mobile() {
        padding-left: 0;
    }

    input[type='file'] {
        visibility: hidden;
    }

    h3 {
        font-family: Arial, Helvetica, sans-serif;
    }

    button.ui.button.upload-button {
        color: $intellyq-main;
        border: 2px solid $intellyq-grey-dark;
        border-radius: 5px;
        border-style: dashed;
        font-size: $intellyq-huge-font-size;
        padding: 6rem 6rem;
        font-weight: 600;
        margin-top: 2rem;
        background-color: #fbfdff;
        margin-bottom: 6rem;

        @include apply-to-mobile() {
            margin-left: 0;
        }

        span.plus-icon {
            font-size: 5rem;
            font-weight: 400;
        }

        span.upload-icon {
            color: $intellyq-font-color-light-grey;
            margin-right: 15px;
            transform: translateY(3px);
        }
    }

    .DocumentPreview {
        display: flex;
        float: left;
        margin-top: -21.5rem;
        max-width: 80%;
        margin-left: 18rem;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        @media screen and (max-width: 910px) {
            float: none;
            margin-top: -2.5rem;
            margin-left: 0;
            overflow: scroll;
            max-width: 100%;
            flex-wrap: nowrap;
            margin-bottom: 2rem;
        }

        .preview-image {
            max-height: 15.5rem;
            max-width: 20em;
            margin-bottom: 1rem;

            @media screen and (min-width: 1309px) and (max-width: 1379px) {
                max-width: 18em;
            }

            @include apply-to-mobile() {
                max-width: 18em;
            }
        }

        .preview-pdf {
            max-width: 30rem;
            max-height: 15.5rem;
            border: 2px solid $intellyq-main;
            padding: 4.8rem 6rem;
            color: $intellyq-font-color-light-grey;
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @media screen and (max-width: 823px) {
                padding: 13% 12%;
            }

            .preview-pdf-icon {
                margin-bottom: 1rem;

                svg#Capa_1 {
                    height: 3rem;
                    fill: $intellyq-main;
                }
            }
        }

        .preview-image-remove-button {
            font-family: Arial, Helvetica, sans-serif;
            height: 2.5rem;
            position: relative;
            right: 2.6rem;
            border-radius: 0px;
            font-size: 1.6rem;
            top: -0.3rem;
            padding: 0.6rem 1rem 2.5rem 1rem;
            background: none;
            color: #ffffff;
            opacity: 0.5;

            &.pdf {
                color: black;
            }

            &:hover {
                background: none;
                text-decoration: none;
            }
        }
    }
}
