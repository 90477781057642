@import '../constants.scss';

.ToS {
    width: 100%;
    padding-top: 4rem;

    h1 {
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        margin-bottom: 10px;
    }

    .last-modified {
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        margin-bottom: 4rem;
    }

    .ui.container.content {
        margin: 2rem 0;
        margin-bottom: 8rem;
        font-family: Arial, Helvetica, sans-serif;
        width: 82% !important;
        max-width: none !important;
        margin-left: auto !important;
        margin-right: auto !important;

        p {
            margin-top: 0.5rem;
            margin-bottom: 2rem;
            font-family: Arial, Helvetica, sans-serif;
        }

        li {
            list-style-type: disc;
            margin-left: 1.5rem;
            font-family: Arial, Helvetica, sans-serif;
        }

        ul,
        ol {
            margin-bottom: 2rem;
        }

        ol {
            li {
                list-style-type: decimal;
                margin-bottom: 1rem;
            }
        }

        h2,
        h3,
        h4 {
            font-family: Arial, Helvetica, sans-serif;
        }

        h4 {
            font-size: 1.35rem;
            margin-top: 2rem;
        }

        h2 {
            margin-top: 3rem;
        }

        a {
            font-family: Arial, Helvetica, sans-serif;
            color: $intellyq-main;
            font-weight: 600;
        }

        span.bolded {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
        }
    }

    .intellyq-logo-link {
        width: 8%;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 500px) {
            width: 25%;
        }

        .intellyq-logo {
            height: 2.5rem;
        }
    }
}
