@import '../constants.scss';

.DropdownMenu {
    transition: 300ms ease-out;
    top: 4.35rem;
    left: 0;
    z-index: 4;
    height: calc(100% - 4.35rem);
    width: 100%;
    position: fixed;
    background-color: white;
    font-size: $intellyq-medium-font-size;

    &.appear-enter {
        top: -100%;
    }

    &.appear-enter-active {
        top: 4.35rem;
    }

    &.appear-exit-active {
        top: -100%;
    }

    div.ui.vertical.options-container.menu {
        padding-left: 1rem;
        width: 100%;
        height: 100%;
        border: none;

        div.item {
            font-family: Arial, Helvetica, sans-serif;
            font-size: $intellyq-very-small-font-size;
            color: $intellyq-font-color;
            cursor: pointer;
        }

        div.item.menu-options {
            padding-left: 1rem;

            div.menu.dropdown-nav {
                margin-top: 0 !important;

                div.item {
                    a {
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: $intellyq-very-small-font-size;
                        color: $intellyq-font-color;
                    }

                    &:hover {
                        background-color: $intellyq-grey-lightest;
                    }
                }
            }
        }

        div.item.dropdown-signup-or-login {
            a.item.logout {
                font-family: Arial, Helvetica, sans-serif;
                padding-left: 0;
            }

            div.menu {
                color: $intellyq-font-color;
                // semantic UI uses !important so we need to do that as well
                // to override the css
                margin-top: 1rem !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 0 !important;

                button.ui.button.dropdown-login {
                    font-family: Arial, Helvetica, sans-serif;
                    background-color: transparent;
                    font-size: $intellyq-small-font-size;
                    margin-right: 1.5rem;
                    border: 1px solid $intellyq-grey-light;
                    border-radius: 2px;
                }

                button.ui.button.dropdown-signup {
                    font-family: Arial, Helvetica, sans-serif;
                    background-color: $intellyq-main;
                    font-size: $intellyq-small-font-size;
                    margin-left: 1.5rem;
                    border: 1px solid $intellyq-main;
                    color: $intellyq-white;
                    border-radius: 2px;
                }
            }
        }
    }
}

.App .DropdownMenu {
    z-index: 3;
}